<template>
    <div class="container">
        <p class="title" style="font-size: 28px; margin-bottom: 10px;">粽子飘香，粽情绵长</p>
        <p class="time" style="font-size: 18px; margin-bottom: 20px;">2021-06-11 13:50</p>
        <img src="../../static/img/consultCenterImg/news-4/1.jpeg" style="width: 40%; display: inline-block; margin-right: 30%;" alt="">
        <img src="../../static/img/consultCenterImg/news-4/2.jpeg" style="width: 25%; display: inline-block;" alt="">
        <p style="margin: 30px 0;">端午节小孩佩香囊，传说有避邪驱瘟之意。
        <br>香囊作各种不同形状，结成一串，
        <br>形形色色，小巧可爱，
        <br>现已成为一种普遍的工艺品。
        <br>在中国南方一些城市，
        <br>青年男女还用香囊来表达浓浓爱意。</p>
        <img src="../../static/img/consultCenterImg/news-4/3.jpeg" class="last" alt="">
        <img src="../../static/img/consultCenterImg/news-4/4.jpeg" class="last" alt="">
        <img src="../../static/img/consultCenterImg/news-4/5.jpeg" class="last" alt="">
    </div>
</template>
<script>
export default {created() {
      this.isActive = this.$route.params.index;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped>
    * {
        padding: 0;
        box-sizing: border-box;
        margin: 0;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: bold;
        color: #000000;
    }

    .title, .time{
        display: block;
        margin: auto;
        text-align: center;
    }

    .last{
        width: 50%; 
        display: block;
        margin: auto; 
        margin-bottom: 40px;
    }
</style>